
<template>
    <div>
        <div class="listAll">
            <el-image src="http://222.168.10.38:8077/cczcimage/20231016/7209273a57c942828e58407cd805235a.png" class="list_top_img"></el-image>
            <el-image src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="list_top_img_min"></el-image>
            <div class="list_title">仲裁员名册</div>
            <div class="list">
                <div class="list_item" v-for="(item, index) in psnList" :key='index'>
                    <el-image
                            class="list_item_img"
                            :src="item.img==''?'http://222.168.10.38:8077/cczcimage/20231103/5c6f8a4846894f28a37153b80c9f1eb1.jpeg':item.img"
                            fit="cover"></el-image>
                    <div class="list_item_bg"></div>
                    <div class="list_item_name">{{item.name}}</div>
                    <div class="list_item_text">
                        <div class="ist_item_text1">性<span style="opacity:0">啊啊</span>别:&nbsp;&nbsp;&nbsp;&nbsp;{{item.sex=='0'?'男': '女'}}</div>
                        <div class="ist_item_text1">出生年月:&nbsp;&nbsp;&nbsp;&nbsp;{{item.dateOfBirth}}</div>
                        <div class="ist_item_text1">职<span style="opacity:0">啊啊</span>业:&nbsp;&nbsp;&nbsp;&nbsp;{{item.work}}</div>
                        <div class="ist_item_text1">职<span style="opacity:0">啊啊</span>称:&nbsp;&nbsp;&nbsp;&nbsp;{{item.level}}</div>
                        <div class="ist_item_text1">政治面貌:&nbsp;&nbsp;&nbsp;&nbsp;{{item.political}}</div>
                        <div class="ist_item_text1">能否涉外:&nbsp;&nbsp;&nbsp;&nbsp;{{item.isOut=='0'?'否':'是'}}</div>
                        <div class="ist_item_text1">擅长专业:&nbsp;&nbsp;&nbsp;&nbsp;{{item.profession}}</div>
                    </div>
                </div>
            </div>
            <el-pagination
                    style="margin-top: 20px;margin-bottom: 40px"
                    background
                    layout="total, prev, pager, next"
                    :total="params.total"
                    :current-page="params.page"
                    :page-size="params.limit"
                    @current-change="pageChangeHandle">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                params: {
                    page: 1,
                    limit: 8,
                    type: '',
                    orderField: 'img',
                    order: "desc",
                    total: 0
                },
                psnList: []
            }
        },

        components: {

        },

        watch: {

        },

        mounted() {
            this.getPsnList();
        },

        methods: {
            //获取新闻列表
            getPsnList() {
                this.$axios.get(this.$httpUrl + '/CczcArbitrator/cczcarbitrator/page',{
                    params: this.params
                }).then(res => {
                    if (res.data.code != 0) {
                        return this.$message.error(res.data.data.msg);
                    }
                    this.psnList = res.data.data.list
                    this.params.total = res.data.data.total
                })
            },

            pageChangeHandle (val) {
                this.params.page = val
                this.getPsnList()
            },
        }
    }
</script>

<style scoped>
    .listAll {
        position: relative;
        text-align: center;
    }

    .list_top_img {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: auto;
    }

    .list_top_img_min{
        position: absolute;
        width: 25%;
        height: auto;
        top: 200px;
        left: 37.5%;
    }

    .list_title {
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }

    .list {
        margin-top: 50px;
        width: 60%;
        margin-left: 20%;
        margin-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
    }

    .list_item {
        position: relative;
        width: 23%;
        height: 250px;
        cursor: pointer;
        margin-top: 20px;
        text-align: center;
        margin-left: calc(8% / 5);
        background-color: white;
    }

    .list_item_img {
        position:absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .list_item:hover .list_item_bg {
        opacity: 0.7;
    }

    .list_item:hover .list_item_name {
        opacity: 1;
    }

    .list_item:hover .list_item_text {
        opacity: 1;
    }

    .list_item_bg {
        position:absolute;
        width: 100%;
        height: 70%;
        line-height: 50px;
        background-color: black;
        opacity: 0;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        bottom: 0px;
        color: white;
    }

    .list_item_name {
        position:absolute;
        top: 32%;
        font-weight: 700;
        font-size: 14px;
        color: white;
        width: 100%;
        opacity: 0;
    }

    .list_item_text {
        position:absolute;
        top: 40%;
        font-size: 12px;
        color: white;
        width: 90%;
        left: 5%;
        opacity: 0;
    }

    .ist_item_text1 {
        text-align: left;
    }
</style>
